<template>
  <div>
    <TheNavigationNew />
    <router-view v-slot="{ Component }">
      <!-- <transition name="page-fade" mode="out-in"> -->
        <component :is="Component"></component>
      <!-- </transition> -->
    </router-view>
    <FooterNew />
    <LoaderNew :visible="pageLoading" fixed :opacity="1" />
    <transition name="fade">
      <NotificationNew v-if="globalNotification" :message="globalNotification.val" />
    </transition>

    <transition name="fade">
      <div v-if="globalErrorShown" class="global-error warning fixed">
        {{ globalError }}
        <img
          class="close"
          src="@/assets/images/close-circle.svg"
          @click="setGlobalError(false)"
        >
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'

import TheNavigationNew from '@/components/TheNavigationNew.vue'
import LoaderNew from '@/components/LoaderNew'
import FooterNew from '@/components/FooterNew'
import NotificationNew from '@/components/NotificationNew'
import Intercom from '@intercom/messenger-js-sdk'

Intercom({
  app_id: 'rokhj26f'
})

export default defineComponent({
  components: {
    TheNavigationNew,
    LoaderNew,
    FooterNew,
    NotificationNew
  },

  computed: {
    ...mapState([
      'firebaseAuth',
      'firestore',
      'usersCollection',
      'pageLoading',
      'globalNotification',
      'globalError',
      'globalErrorShown'
    ]),
    ...mapGetters([
      'currentUser',
      'userProfile'
    ])
  },

  methods: {
    ...mapMutations([
      'setCurrentUser',
      'updateCurrentUser',
      'setModalSignup',
      'setModalAdditionalInfo',
      'setModalOffer',
      'setGlobalError'
    ]),
    ...mapActions([
      'userAuthCheck',
      'userProfileListener',
      'getMySurveys'
    ])
  },

  async mounted () {
    await this.userAuthCheck() // Check to see if user is signed in, etc... regardless of route
    // If there's a logged in user, we'll go ahead and get surveys to use in the UI
    if (this.currentUser) {
      try {
        await this.getMySurveys()
      } catch (err) {
        this.setGlobalError('There was an error retrieving your surveys.')
      }
    }
    // await nextTick()
    if (this.$route.query.signUp) {
      this.setModalSignup(true)
    }
    if (this.$route.query.additionalinfo) {
      this.setModalAdditionalInfo(true)
    }
    if (this.$route.query.offer) {
      this.setModalOffer(true)
    }
  }
})
</script>
<style lang="scss">

// Import global SCSS that will actually be rendered by the browser
// (should not include variables, etc...)
@import "./assets/theme/css/style.css";
@import "./assets/theme/css/custom.css";
@import "@/assets/style/rendered.scss";
// @import "./assets/style/new/owl.carousel.min.css";

</style>
