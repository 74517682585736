<template>
  <div class="form__style-radio-1 form__style-radio--overall StyleSelector">
    <div class="form__content--wide">
      <div class="form__item--wide">
        <div class="form__item form__item--radio">
          <div class="form__item--radio__header style-selector">
            <StyleSection :key="this.typeOfFurniture" title="Draper" :room="this.typeOfFurniture" description="Clean lines, sleek edges, natural woods, and dark fabrics. Functionality over form.
            Timeless minimalism. Inspired by the staff at Sterling Cooper." subtitle="Classic Cool" variant="carousel" baseDirectory="survey-exploration"/>
            <div class="btns-set">
              <div class="redioBTNStyle">
                <input type="radio" name="style-first"
                  v-model="inputValue[0]"
                  value="draper"
                  id="style1-first"
                  class="form__style-radio--ranking--first"
                  @click="radioClicked(0, 'draper')"
                  @input="updateValue"
                >
                <button type="button" class="choiceBTN">My choice</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form__item--wide">
        <div class="form__item form__item--radio">
          <div class="form__item--radio__header style-selector">
            <StyleSection :key="this.typeOfFurniture" title="Moody" :room="this.typeOfFurniture" description="Softer lines, woven materials &whitewashed woods including oak, teak and ash. Comfort meets style.
            Warm & welcoming. Furniture you might find on Californiacation or Big Little Lies." subtitle="Casual elegance" variant="carousel" baseDirectory="survey-exploration"/>
            <div class="btns-set">
            <div class="redioBTNStyle">
              <input type="radio"
                v-model="inputValue[0]"
                value="moody"
                name="style-first"
                id="style2-first"
                class="form__style-radio--ranking--first"
                @click="radioClicked(0, 'moody')"
                @input="updateValue"
              >
              <button type="button" class="choiceBTN">My choice</button>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="form__item--wide">
        <div class="form__item form__item--radio">
          <div class="form__item--radio__header style-selector">
            <StyleSection :key="this.typeOfFurniture" title="Roy" :room="this.typeOfFurniture" description="Stark, rough, unfinished forms, dark leather, metal & glass, monochrome color scheme.
            Mainstream masculinity, a style suited for Succession’s Roman or Kendall." subtitle="Edgy yet grounded" variant="carousel" baseDirectory="survey-exploration"/>
            <div class="btns-set">
              <div class="redioBTNStyle">
                <input type="radio"
                  v-model="inputValue[0]"
                  value="roy"
                  name="style-first"
                  id="style3-first"
                  class="form__style-radio--ranking--first"
                  @click="radioClicked(0, 'roy')"
                  @input="updateValue"
                >
                <button type="button" class="choiceBTN">My choice</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ValidationWarning :valid="valid" text="Please choose your favorite style." />
    </div>
  </div>
</template>

<script>
import StyleSection from '@/components/InspirationGallery/StyleSection.vue'
import ValidationWarning from '@/components/quiz/questions/ValidationWarning.vue'

export default {
  name: 'StyleSelector',
  components: {
    ValidationWarning,
    StyleSection
  },
  props: {
    typeOfFurniture: {
      type: String,
      default: 'Living room'
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      inputValue: [],
      radioValue: ''
    }
  },
  mounted () {
    this.inputValue = this.modelValue || []
  },
  methods: {
    radioClicked (index, style) {
      // Clear the radio if an item is clicked again
      if (this.inputValue[index] === style) {
        this.inputValue.splice(index, 1)
        this.updateValue()
      }
    },
    updateValue () {
      this.$emit('update:modelValue', this.inputValue)
    },
    validate () {
      if (!this.required) {
        this.valid = true
      } else {
        // only the 1st choice is required
        this.valid = !!this.modelValue[0]
      }
      return this.valid
    }
  },
  watch: {
    modelValue: {
      handler () {
        this.validate()
      },
      deep: true
    }
  }
}
</script>
<style>
.form__item--radio__header.style-selector {
  grid-column: span 12 !important;
}
</style>
