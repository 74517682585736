<template>
  <div class="carouselWrapper">
  <h3 class="header">
    {{ title }}
  </h3>
  <div class="image-list-wrapper">
    <div
      class="horizontal-image-list"
      :class="{ 'is-dragging': isDragging }"
      ref="container"
      @mousedown="startDrag"
      @mousemove="drag"
      @mouseup="endDrag"
      @mouseleave="endDrag"
      @touchstart="startDrag"
      @touchmove="drag"
      @touchend="endDrag"
    >
      <div class="image-container" ref="imageContainer">
        <CloudinaryImage
          v-for="image in images"
          :key="image"
          :public-id="image"
          :alt="`Image option`"
          class="image"
          @image-loaded="onImageLoad"
        />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions'
import { mapState } from 'vuex'
import CloudinaryImage from '@/components/CloudinaryImage.vue'

export default {
  name: 'CarouselItem',
  components: { CloudinaryImage },
  props: {
    baseUrl: String,
    title: String
  },
  data () {
    return {
      images: [],
      isDragging: false,
      startX: 0,
      scrollLeft: 0
    }
  },
  computed: {
    ...mapState(['fbFunctions'])
  },
  methods: {
    async fetchImages () {
      const res = await httpsCallable(this.fbFunctions, 'listAllAssetsFromFolderFromCloudinary')({ path: this.baseUrl })
      this.images = res.data?.assets ?? []
    },
    startDrag (e) {
      this.isDragging = true
      this.startX = e.type.includes('mouse') ? e.pageX : e.touches[0].pageX
      this.scrollLeft = this.$refs.container.scrollLeft
    },
    drag (e) {
      if (!this.isDragging) return
      e.preventDefault()
      const x = e.type.includes('mouse') ? e.pageX : e.touches[0].pageX
      const distance = x - this.startX
      this.$refs.container.scrollLeft = this.scrollLeft - distance
    },
    endDrag () {
      this.isDragging = false
    }
  },
  mounted () {
    this.fetchImages()
  }
}
</script>

<style scoped>
.image-list-wrapper {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.horizontal-image-list {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  cursor: grab;
}

.image-list-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
  width: 4rem;
  left: -1px;
  background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
}

.image-list-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
  width: 4rem;
  right: -2px;
  background: linear-gradient(270deg,#fff 0,hsla(0,0%,100%,0))
}

.horizontal-image-list.is-dragging {
  cursor: grabbing;
}

.horizontal-image-list::-webkit-scrollbar {
  display: none; /* WebKit */
}

.image-container {
  display: inline-block;
  white-space: nowrap;
}

.image {
  display: inline-block;
  width: 15%;
  margin-right: 10px;
  vertical-align: top;
  pointer-events: none;

  @media (max-width: 768px) {
    width: 30%;
  }
}

.header {
  color: #004021;
  font-size: 24px;
}

.carouselWrapper {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  justify-content: center;
  align-items: center;
}
</style>
