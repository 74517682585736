<template>
  <!-- header -->
  <header>
      <nav class="navbar " :class="{ 'sticky': isSticky, 'fixed-top': fixTop, 'innerHeader': innerHeader, 'nav-opened': isMenuOpen }" >
        <div class="container-fluid" id="top-navigation">
            <router-link class="navbar-brand active" to="/" v-if="logoControl">
              <img class="Weblogo" src="@/assets/theme/images/logo.svg">
              <img class="mobilelogo" src="@/assets/theme/images/logo-color.svg">
            </router-link>
            <router-link class="navbar-brand active"  to="/" v-if="!logoControl">
              <img src="@/assets/theme/images/logo-color.svg">
            </router-link>
            <button class="menu-toggler ms-2" type="button" @click="toggleMenuOpen" :class="{ 'i-change': isMenuOpen }">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="menu-group">
              <div class="menu" v-if="!currentUser && !isMenuOpen">
                <ul>
                    <li>
                      <router-link class="active" to="/about">Our story</router-link>
                    </li>
                    <li>
                      <router-link class="active" to="">Support</router-link>
                      <ul class="submenu">
                        <li><router-link class="active" to="/support">FAQ</router-link></li>
                        <li><router-link class="active" to="/contact">Contact us</router-link></li>
                      </ul>
                    </li>
                    <li>
                      <router-link class="active" to="">Resources</router-link>
                      <ul class="submenu">
                        <li><router-link class="active" to="/sustainability">Sustainability</router-link></li>
                        <li><router-link class="active" to="/resources">Donating &amp; recycling</router-link></li>
                        <li><router-link class="active" to="/glossary">Glossary</router-link></li>
                      </ul>
                    </li>
                  <li>
                    <router-link class="active" to="/inspiration-gallery?room=Living Room">Explore</router-link>
                  </li>
                </ul>
                <button type="button"  :class="{ 'btn-light': btnLight, 'btn-Border': btnBorder}"  @click="showModalSignup">Sign in</button>
                <router-link type="button" class="btn-yello" to="/quiz" >Get started <span class="icon-double-arrow"></span></router-link>
              </div>
              <div class="menu-mobile" v-if="isMenuOpen" style="height: 100vh; overflow-y: auto;" >
                <ul>
                    <li>
                      <router-link class="active" to="javascript:void(0)"><span class="icon-double-arrow"></span>Our story</router-link>
                      <ul>
                        <li><router-link @click="closeMenu" to="/about">Story</router-link></li>
                        <li><router-link @click="closeMenu" to="/about#team">Team</router-link></li>
                        <li><router-link @click="closeMenu" to="/about#brands">Brands</router-link></li>
                      </ul>
                    </li>
                    <li>
                      <router-link class="active" to="javascript:void(0)"><span class="icon-double-arrow"></span>Resources</router-link>
                      <ul>
                        <li><router-link @click="closeMenu" to="/sustainability">Sustainability</router-link></li>
                        <li><router-link @click="closeMenu" to="/resources">Donating & recycling</router-link></li>
                        <li><router-link @click="closeMenu" to="/glossary">Glossary</router-link></li>
                      </ul>
                    </li>
                    <li>
                      <router-link class="active" to=""><span class="icon-double-arrow"></span>Support</router-link>
                      <ul>
                        <li><router-link @click="closeMenu" to="/contact">Contact</router-link></li>
                        <li><router-link @click="closeMenu" to="/support">FAQ</router-link></li>
                      </ul>
                    </li>
                    <li>
                      <router-link class="active" @click="closeMenu" to="/inspiration-gallery?room=Living%20Room"><span class="icon-double-arrow"></span>Explore</router-link>
                    </li>
                    <li v-if="currentUser">
                      <router-link class="active" to="javascript:void(0)"><span class="icon-double-arrow"></span>My Account</router-link>
                      <ul>
                        <li><router-link @click="closeMenu" to="/profile">Account settings</router-link></li>
                        <li :class="{ active: $route.path == '/projects' }" @click="closeMenu()" >
                          <router-link to="/projects">
                            Projects
                            <div v-if="mySurveys && mySurveys.length" class="site-header__nav__item__notification">
                              {{ mySurveys.length }}
                            </div>
                          </router-link>
                        </li>
                        <li :class="{ active: $route.path == '/orders' }"
                          @click="closeMenu()"
                        >
                          <router-link to="/orders">
                            Orders
                          </router-link>
                        </li>
                      </ul>
                    </li>
                    <!-- <li>
                      <router-link class="active" to="/support"><span class="icon-double-arrow"></span>FAQ</router-link>
                    </li> -->
                </ul>
                <button type="button"  v-if="!currentUser" class="btn-light" @click="showModalSignup">Sign in</button>
                <button  v-if="currentUser"  @click="logout" to="/" type="button" class="btn-light">Sign out</button>
                <router-link type="button" class="btn-yello" to="/quiz" >Get started <span class="icon-double-arrow"></span></router-link>
                <footer>
                  <div class="container-fluid">
                    <div class="Footertop">
                        <div class="getintouch">
                          <h6>Stay in the loop</h6>
                          <p>Join our newsletter to receive periodic updates and promotions.</p>
                            <form @submit.prevent="submitForm" ref="signUpForm" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                              class="validate site-footer__section__form site-footer__section__form--newsletter"
                              target="_blank"
                              novalidate
                            >
                              <div class="inputGrip">
                                <input type="text" name="EMAIL" v-model="signUpForm.email" class="inputstyle required email" id="mce-EMAIL" placeholder="Enter your email address" required>
                                <button type="submit" name="subscribe" id="mc-embedded-subscribe" class="btn-Border" form="mc-embedded-subscribe-form">Subscribe</button>
                              </div>
                              <div id="mce-responses" class="form__item clear">
                                  <div v-if="signUpError" class="warning" id="mce-error-response">
                                    <!-- {{ signUpError }} -->
                                    <span class="closeicon"><i class="icon-close"></i></span>
                                    <div>
                                      <h5>There was a problem with your submission.</h5>
                                      <p class="errorsub"><span></span>
                                        {{ signUpError }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                            </form>
                          <Loader :visible="loading" fixed blocking />
                        </div>
                    </div>
                    <div class="footerbottom">
                        <div class="copyright">
                          <ul>
                              <li>@ {{ new Date().getFullYear() }}</li>
                          </ul>
                        </div>
                        <div class="social">
                          Follow us:
                          <a href="https://www.instagram.com/totreeco/"><i class="fa-brands fa-instagram"></i></a>
                          <a href="https://www.facebook.com/profile.php?id=100069084462571"><i class="fa-brands fa-facebook"></i></a>
                        </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
            <div class="menu after-login-user" v-if="currentUser && !isMenuOpen">
              <ul>
                  <li :class="{ active: $route.path === '/projects' }" @click="toggleMenu()" >
                    <router-link to="/projects">
                      Projects
                      <div v-if="mySurveys && mySurveys.length" class="site-header__nav__item__notification">
                        {{ mySurveys.length }}
                      </div>
                    </router-link>
                  </li>
                  <li :class="{ active: $route.path === '/orders' }"
                    @click="toggleMenu()"
                  >
                    <router-link to="/orders">
                      Orders
                    </router-link>
                  </li>
                  <li>
                    <router-link class="active" to="/about">Our story</router-link>
                  </li>
                  <li>
                    <router-link class="active" to="/inspiration-gallery?room=Living Room">Explore</router-link>
                  </li>
                  <!-- <li :class="{ active: $route.path == '/support' }"
                    @click="toggleMenu()"
                  >
                    <router-link to="/support">Support</router-link>
                  </li> -->
                  <li>
                      <router-link class="active" to="">Support</router-link>
                      <ul class="submenu">
                        <li><router-link class="active" to="/support">FAQ</router-link></li>
                        <li><router-link class="active" to="/contact">Contact us</router-link></li>
                      </ul>
                    </li>
                  <li>
                    <button
                      class="site-header__nav__item--user__toggle"
                      v-on:click="menuActive = !menuActive"
                    >
                      {{ userInitials }}
                    </button>
                    <ul class="site-header__nav__item--user__menu" v-show="menuActive">
                      <li><router-link to="/profile" @click="menuActive = !menuActive">Account settings</router-link></li>
                      <li><router-link v-if="currentUser" @click="logout" to="/">Sign out</router-link></li>
                    </ul>
                  </li>
              </ul>
            </div>

            <transition name="fade">
              <ModalSignUp @closeModalSignupEvent="closeModalSignup" v-show="getModalSignUp" />
            </transition>
<!--            <transition name="fade">-->
<!--              <ModalAdditionalInfo @closeModalAdditionalInfoEvent="closeModalAdditionalInfo" v-show="getModalAdditionalInfo" />-->
<!--            </transition>-->
        </div>
      </nav>
      <transition name="fade">
        <ModalOffer @closeModalOfferEvent="closeModalOffer" v-show="getModalOffer" />
      </transition>
  </header>
</template>
 <style>
    .sticky {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
    }
</style>
<script>
import { defineComponent } from 'vue'
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import ModalSignUp from './ModalSignUp.vue'
import { httpsCallable } from 'firebase/functions'
import ModalOffer from './ModalOffer.vue'
import Loader from '@/components/Loader.vue'
export default defineComponent({
  name: 'TheNavigationNew',
  components: {
    Loader,
    ModalSignUp,
    // ModalAdditionalInfo,
    ModalOffer
  },
  data () {
    return {
      signUpForm: {},
      signUpError: '',
      isModalSignupVisible: false,
      isOpen: false,
      menuActive: false,
      isSticky: false,
      isMenuOpen: false,
      fixTop: true,
      innerHeader: false,
      logoControl: true,
      btnLight: true,
      btnBorder: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'userProfile',
      'userInitials',
      'getModalSignUp',
      'getModalAdditionalInfo',
      'getModalOffer',
      'mySurveys'
    ]),
    ...mapState(['firebaseAuth', 'modals', 'showDebug', 'invertNav'])
  },
  methods: {
    async submitForm () {
      this.signUpError = ''
      this.loading = true
      const valid = this.$refs.signUpForm.reportValidity()
      if (valid) {
        try {
          await httpsCallable(this.fbFunctions, 'mailChimpSignUp')(this.signUpForm)

          this.setGlobalNotification('Success')
          this.signUpForm = {}
        } catch (err) {
          this.signUpError = err.message
        }
      }
      this.loading = false
    },
    ...mapMutations(['setCurrentUser', 'setUserProfile', 'setModalSignup', 'setModalOffer']),
    ...mapActions([
      'logout',
      'userProfileListener'
    ]),
    closeModalSignup () {
      this.setModalSignup(false)
    },
    // closeModalAdditionalInfo () {
    //   this.setModalAdditionalInfo(false)
    // },
    closeModalOffer () {
      this.setModalOffer(false)
    },
    toggleMenu () {
      // Only toggle is below mobile breakpoint. Otherwise always false
      if (window.innerWidth >= 768) {
        this.isOpen = false
      } else {
        this.isOpen = !this.isOpen
      }
    },
    showModalSignup () {
      this.setModalSignup(true)
    },
    closeModalSighup () {
      this.setModalSignup(false)
    },
    showModalAdditionalInfo () {
      this.setModalAdditionalInfo(true)
    },
    showModalOffer () {
      this.setModalOffer(true)
    },
    updateScroll () {
      if (window.scrollY >= 50) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    },
    toggleMenuOpen () {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu () {
      this.isMenuOpen = false
    },
    handleRouteChange (newRoute, oldRoute) {
      const newPath = newRoute.path

      if (newPath !== '/' && newPath !== '/support' && newPath !== '/about' && newPath !== '/sustainability' && newPath !== '/resources' && newPath !== '/glossary') {
        this.fixTop = false
        this.innerHeader = true
        this.logoControl = false
        this.btnLight = false
        this.btnBorder = true
      } else if (newPath === '/support' || newPath === '/about' || newPath === '/sustainability' || newPath === '/resources' || newPath === '/glossary') {
        this.fixTop = true
        this.innerHeader = false
        this.logoControl = true
        this.btnLight = true
        this.btnBorder = false
      } else {
        this.fixTop = true
        this.innerHeader = false
        this.logoControl = true
        this.btnLight = true
        this.btnBorder = false
      }
    }
    // closeModalAdditionalInfo () {
    //   this.setModalAdditionalInfo(false)
    // }

  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
    window.addEventListener('scroll', this.handleScroll)
    this.updateScroll()
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.updateScroll)
  },
  watch: {
    $route: 'handleRouteChange'
  }
})
</script>
